import React, { FunctionComponent } from "react"
import { graphql, Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"

import { renderRichText } from "gatsby-source-contentful/rich-text"
import { BLOCKS, MARKS } from "@contentful/rich-text-types"

import { Blog } from "../types"
import Layout from "../components/Layout"
import SEO from "../components/SEO"

interface Props {
  data: {
    contentfulBlog: Blog
  }
}

const BlogTemplate: FunctionComponent<Props> = ({ data, location }) => {
  function formatDate(date: Date) {
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ]

    return `${months[date.getMonth()]} ${date.getDate()}, ${date.getFullYear()}`
  }

  console.log(data.contentfulBlog.coverImage.url)
  return (
    <Layout>
      <SEO
        description={data.contentfulBlog.metaDescription}
        title={data.contentfulBlog.title}
        image={data.contentfulBlog.coverImage.url}
        link={`https://mooremomentum.com/blog/${data.contentfulBlog.slug}/`}
      />

      <div className="blog max-w-6xl mt-8 mx-auto px-4 blog_text">
        <figure className="mb-8 flex  justify-center rounded-lg">
          <GatsbyImage
            image={data.contentfulBlog.coverImage.gatsbyImageData}
            alt={data.contentfulBlog.metaDescription}
            className="rounded-lg w-50"
            style={{ width: "70%", height: "70%" }}
            loading="eager"
          />
        </figure>

        <h1 className="font-Orbitron mb-4 text-center text-2xl sm:text-3xl lg:text-4xl">
          {data.contentfulBlog.title}
        </h1>

        <h4 className="text-center">
          <b>{formatDate(new Date(data.contentfulBlog.datePublished))}</b>
        </h4>
        <h4 className="text-center">
          <b>By Will Moore</b>
        </h4>

        <div className="py-8 space-y-4 blogitem">
          {/* @ts-ignore */}
          {renderRichText(data.contentfulBlog.content, {
            renderMark: {
              [MARKS.BOLD]: text => <span className="font-bold">{text}</span>,
              [MARKS.ITALIC]: text => <em>{text}</em>,
            },
            renderNode: {
              [BLOCKS.PARAGRAPH]: (node, children) => <p>{children}</p>,
              [BLOCKS.HEADING_1]: (node, children) => (
                <h1 className="font-Orbitron text-2xl">{children}</h1>
              ),
              [BLOCKS.HEADING_2]: (node, children) => (
                <h2
                  className="font-Orbitron text-xl"
                  id={
                    children[0]
                      ? children[0].props?.children
                          .replaceAll(" ", "-")
                          .replaceAll(/\u00a0/g, "-")
                      : children
                          ?.replaceAll(" ", "-")
                          .replaceAll(/\u00a0/g, "-")
                  }
                >
                  {children}
                </h2>
              ),
              [BLOCKS.HEADING_3]: (node, children) => (
                <>
                  {" "}
                  {console.log(children)}
                  <h3
                    className="font-Orbitron text-lg"
                    id={
                      children[0]
                        ? children[0].props?.children
                            .replaceAll(" ", "-")
                            .replaceAll(/\u00a0/g, "-")
                        : children
                        ? children[0]
                            .replaceAll(" ", "-")
                            .replaceAll(/\u00a0/g, "-")
                        : children
                            .replaceAll(" ", "-")
                            .replaceAll(/\u00a0/g, "-")
                    }
                  >
                    {children}
                  </h3>
                </>
              ),
              [BLOCKS.EMBEDDED_ASSET]: node => {
                if (!node.data.target) {
                  return
                }
                // console.log("Imagess",node.data.target.gatsbyImageData)
                const { gatsbyImageData } = node.data.target
                if (!gatsbyImageData) {
                  // asset is not an image
                  return null
                }
                return (
                  <figure className="flex flex-wrap justify-center items-center">
                    {node.data.target.file.fileName ===
                    "button press start to begin BLACK VERSION (1) (1).gif" ? (
                      <a href="/core-values-quiz/" target="_blank">
                        <GatsbyImage image={gatsbyImageData} alt="Blog Image" />
                      </a>
                    ) : node.data.target.file.fileName === "contact.gif" ? (
                      <a href="/life-coach-for-young-adults/" target="_blank">
                        <GatsbyImage image={gatsbyImageData} alt="Blog Image" />
                      </a>
                    ) : (
                      <GatsbyImage
                        image={gatsbyImageData}
                        alt="Blog Image"
                        style={{ maxWidth: "750px" }}
                        objectFit="contain"
                      />
                    )}
                  </figure>
                )
              },
            },
          })}
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query ($slug: String!) {
    contentfulBlog(slug: { eq: $slug }) {
      content {
        raw
        references {
          ... on ContentfulAsset {
            contentful_id
            __typename
            gatsbyImageData
            file {
              fileName
            }
          }
        }
      }
      datePublished
      id
      metaDescription
      slug
      title
      coverImage {
        url
        gatsbyImageData(layout: CONSTRAINED)
        file {
          fileName
        }
      }
    }
  }
`

export default BlogTemplate
